.App {
  font-family: sans-serif;
}

.App-logo {

  pointer-events: none;

}


.card img {
  width: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;
  display: block;
}
.App .card.zoom {
  width: 50%;
  z-index: 1000;
}
.pagesList > div {display: inline-block;}
.pagesList > div span { display : block}
.fullscreen {
  position: absolute;
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  z-index: 1001;
  text-align: center;
  top: 0;
  left: auto;
}
.fullscreen canvas {
  height: 100%;
  margin-left: auto;
}

.ccontainer .middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transition: .5s ease;
  opacity: 0;
  text-align: center;
}
.ccontainer {
  position: relative;
  width: 100%;
}
.ccontainer:hover img {
  opacity: 0.3;
}

.ccontainer:hover .middle {
  opacity: 1;
}

.text {
  background-color: white;
  color: black;
  font-size: 14px;
  padding: 8px 16px;
}
h1{
  font-family: 'Concert One', cursive;
  margin-top: 10px;
  margin-bottom: 20px;
}
.control {

  position: relative;
  display: block;
  left: -1px;
  top: -2px;
  margin-right: -2px;
}
.card {
  position: relative;
}
.control .inner {
  display: none;
  width: 100%;
  height: auto;
  z-index: 10;
  position: absolute;
  background-color: white;
}
.row>div:hover .inner {
  display: block;
}
.dragoverlay {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: .5s ease;
  opacity: 0;
  background-color: white;
  cursor: move;
}
.dragoverlay div {
  text-align:
}
.dragoverlay svg:first-of-type {
  margin-right: 20px
}
.card:hover .dragoverlay {
  opacity: 1
}
.card:hover {
  box-shadow: 0 6px 10px rgba(0,0,0,.08)
}
.how-to img {
  height: 200px;
}
footer .socials{
  text-align: center;
}
footer .socials button{
  margin:5px;
}
.droparea {
  width: 100%;
  border :1px dashed black;
  border-radius: 5px;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px
 }
 .droparea span {
   display: block
 }
.how-to {
  text-align: justify;
  }
.how-to ul {
  list-style-type: none;
  padding-left: 5px;
}
.resizeModal img {
  width: 100%
}
